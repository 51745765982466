// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Orders_section_2ufNW{display:grid;grid-template-rows:auto 1fr;height:100%;background-color:#f8f8f8;color:var(--supster-secondary)}.Orders_sort_185W1,.Orders_tabs_2BovV{background-color:#fff;box-shadow:inset 0 -1px 0 #e5e3e3}.Orders_sort_185W1{display:flex;justify-content:center;padding:13px}.Orders_sortLabel_3SnIZ{margin-right:5px;font-size:15px;line-height:20px}.Orders_select_2_ijZ{display:grid;justify-self:center;width:auto}", ""]);
// Exports
exports.locals = {
	"section": "Orders_section_2ufNW",
	"sort": "Orders_sort_185W1",
	"tabs": "Orders_tabs_2BovV",
	"sortLabel": "Orders_sortLabel_3SnIZ",
	"select": "Orders_select_2_ijZ"
};
module.exports = exports;
