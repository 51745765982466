// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OrdersItem_section_2RnyL{display:grid;padding:15px;background:#fff;border-radius:6px;box-shadow:0 2px 5px rgba(0,0,0,.08);row-gap:9px}.OrdersItem_inner_334H4{row-gap:1px}.OrdersItem_inner_334H4,.OrdersItem_row_1qn_X{display:grid}.OrdersItem_row_1qn_X{grid-template-columns:repeat(2,1fr);-moz-column-gap:6px;column-gap:6px}.OrdersItem_row_1qn_X.OrdersItem_left_1I3l_{grid-template-columns:1fr auto}.OrdersItem_row_1qn_X.OrdersItem_right_3v2Pi{grid-template-columns:auto 1fr}.OrdersItem_row_1qn_X.OrdersItem_min_2a7H2{grid-template-columns:repeat(2,max-content)}.OrdersItem_title_3jjgD{color:#000;font-weight:500;font-size:18px;line-height:24px}.OrdersItem_text_140s3{align-self:center;color:#000;font-weight:400;font-size:12px;line-height:18px}.OrdersItem_text_140s3.OrdersItem_bold_1A-vs{font-weight:500}.OrdersItem_text_140s3.OrdersItem_gray_2OOC1,.OrdersItem_track_3DdJC{color:#a09f9f}.OrdersItem_track_3DdJC{align-self:center;justify-self:center;width:100%;font-weight:400;font-size:12px;line-height:18px;white-space:nowrap;text-align:right;text-overflow:ellipsis}.OrdersItem_separator_3FhIl{width:100%;border-top:1px solid #e5e3e3}.OrdersItem_link_1n4cY{display:grid;grid-template-columns:repeat(2,max-content);color:#000;font-weight:400;font-size:12px;line-height:18px;text-decoration:none;-moz-column-gap:15px;column-gap:15px;transition:opacity .3s}.OrdersItem_link_1n4cY:hover{opacity:.7}.OrdersItem_link_1n4cY svg{align-self:center}", ""]);
// Exports
exports.locals = {
	"section": "OrdersItem_section_2RnyL",
	"inner": "OrdersItem_inner_334H4",
	"row": "OrdersItem_row_1qn_X",
	"left": "OrdersItem_left_1I3l_",
	"right": "OrdersItem_right_3v2Pi",
	"min": "OrdersItem_min_2a7H2",
	"title": "OrdersItem_title_3jjgD",
	"text": "OrdersItem_text_140s3",
	"bold": "OrdersItem_bold_1A-vs",
	"gray": "OrdersItem_gray_2OOC1",
	"track": "OrdersItem_track_3DdJC",
	"separator": "OrdersItem_separator_3FhIl",
	"link": "OrdersItem_link_1n4cY"
};
module.exports = exports;
