<template>
  <div :class="[$style.section, $style[color], $style[size]]">
    <p :class="[$style.text, $style[size]]">
      <slot />
    </p>
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'OrderMarker',
  props: {
    size: {
      default: 'md',
      validator: (v) => ['sm', 'md', 'lg', 'xl'].includes(v),
      type: String,
    },
    color: {
      default: 'default',
      validator: (v) => ['default', 'primary', 'transparent'].includes(v),
      type: String,
    },
  },
})
</script>

<style lang="scss" module>
.section {
  display: grid;
  align-self: center;
  border-radius: 12px;

  &.default {
    color: white;
    background-color: $secondary-medium;
    border: 1px solid $secondary-medium;
  }

  &.primary {
    color: white;
    background-color: $primary-light-background;
    border: 1px solid $primary-light-background;
  }

  &.transparent {
    color: black;
    background-color: transparent;
    border: 1px solid black;
  }

  &.sm {
    padding: 10px;
  }

  &.md {
    padding: 3px 9px !important;
  }

  &.lg {
    padding: 5px 12px !important;
  }

  &.xl {
    padding: 5px 15px !important;
  }
}

.text {
  align-self: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;

  &.sm {
    font-weight: 400;
    line-height: 14px;
  }

  &.md {
    font-weight: 500;
    line-height: 18px;
  }

  &.lg {
    font-weight: 500;
    line-height: 18px;
  }

  &.xl {
    font-weight: 500;
    line-height: 18px;
  }
}
</style>
