<template>
  <section :class="$style.section">
    <v-tabs
      :tabs="tabs"
      :active-index.sync="tabIndex"
      :class-name="$style.tabs"
      color="default"
      is-single-tab
    >
      <template #header>
        <div :class="$style.sort">
          <span :class="$style.sortLabel">{{ $t('sorting') }}:</span>
          <select-dropdown
            name="orders"
            color="transparent-light"
            icon-size="lg"
            :class-name="$style.select"
            :list="sortList"
            :select-value="sortList[0].value"
          />
        </div>
      </template>
      <template #content>
        <v-grid :length="ordersLength(tabName)" class-name="orders" no-data @infinite="onGetOrders">
          <orders-item v-for="order of orders(tabName)" :key="order.id" :order="order" />
        </v-grid>
      </template>
    </v-tabs>
  </section>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import VTabs from '@layouts/VTabs.vue'
import SelectDropdown from '@dropdowns/list/SelectDropdown.vue'
import VGrid from '@layouts/VGrid.vue'
import Config from '@config/index'
import { getQueryParams } from '@utils/url-formatter'
import OrdersItem from './list/OrdersItem.vue'

export default Vue.extend({
  name: 'Orders',
  components: { SelectDropdown, VTabs, VGrid, OrdersItem },
  data() {
    return {
      tabIndex: 0,
      tabs: [
        { value: this.$t('all'), name: 'all' },
        { value: this.$t('inProcess'), name: 'in-process' },
        { value: this.$t('completed'), name: 'completed' },
      ],
      sortList: [{ item: 'newest', value: this.$t('newestFirst') }],
      cacheHandlersMapId: null,
    }
  },
  computed: {
    ...mapGetters('Orders', ['orders', 'ordersLength']),
    ...mapGetters('Page', ['pageIsFooter']),
    tabName() {
      return this.tabs[this.tabIndex].name
    },
  },
  methods: {
    ...mapActions('Orders', ['getOrders']),
    ...mapMutations('Page', ['setPageTitle', 'setPageBack', 'setPageIsFooter']),
    ...mapMutations('Orders', ['setOrders', 'resetOrders']),
    onGetOrders(scroll) {
      this.getOrders({ scroll, tab: this.tabName })
    },
  },
  async mounted() {
    this.cacheHandlersMapId = this.$cacheManager.add({
      regExpURL: Config.URLRegExps.ORDERS,
      cacheHandlers: [
        (data, updatedUrl) => {
          const urlSearchParams = getQueryParams(updatedUrl)
          const deliveryCode = urlSearchParams.delivery
          if (deliveryCode) {
            const tab = deliveryCode === 3 ? 'completed' : 'in-progress'
            this.setOrders({ orders: data, tab })
          } else {
            this.setOrders({ orders: data, tab: 'all' })
          }
        },
      ],
    })
    this.setPageTitle({ name: 'orders.title' })
    if (!this.pageIsFooter) {
      this.setPageIsFooter(true)
    }
  },
  beforeDestroy() {
    this.resetOrders()
    if (this.cacheHandlersMapId) {
      this.$cacheManager.remove([this.cacheHandlersMapId])
    }
  },
})
</script>
<style lang="scss" module>
.section {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  background-color: $shadow;
  color: $secondary;
}

.tabs {
  background-color: white;
  box-shadow: inset 0 -1px 0 $secondary-light;
}

.sort {
  display: flex;
  justify-content: center;
  padding: 13px;
  background-color: white;
  box-shadow: inset 0 -1px 0 $secondary-light;
}

.sortLabel {
  margin-right: 5px;
  font-size: 15px;
  line-height: 20px;
}

.select {
  display: grid;
  justify-self: center;
  width: auto;
}
</style>
