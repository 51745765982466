// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OrderMarker_section_2N26d{display:grid;align-self:center;border-radius:12px}.OrderMarker_section_2N26d.OrderMarker_default_3PjXk{color:#fff;background-color:#a09f9f;border:1px solid #a09f9f}.OrderMarker_section_2N26d.OrderMarker_primary_27Ixe{color:#fff;background-color:var(--supster-primary-light-background);border:1px solid var(--supster-primary-light-background)}.OrderMarker_section_2N26d.OrderMarker_transparent_164Ms{color:#000;background-color:transparent;border:1px solid #000}.OrderMarker_section_2N26d.OrderMarker_sm_4xOeN{padding:10px}.OrderMarker_section_2N26d.OrderMarker_md_1cqpA{padding:3px 9px!important}.OrderMarker_section_2N26d.OrderMarker_lg_3wLK7{padding:5px 12px!important}.OrderMarker_section_2N26d.OrderMarker_xl_3eOAU{padding:5px 15px!important}.OrderMarker_text_27Rci{align-self:center;justify-content:center;font-weight:500;font-size:12px}.OrderMarker_text_27Rci.OrderMarker_sm_4xOeN{font-weight:400;line-height:14px}.OrderMarker_text_27Rci.OrderMarker_lg_3wLK7,.OrderMarker_text_27Rci.OrderMarker_md_1cqpA,.OrderMarker_text_27Rci.OrderMarker_xl_3eOAU{font-weight:500;line-height:18px}", ""]);
// Exports
exports.locals = {
	"section": "OrderMarker_section_2N26d",
	"default": "OrderMarker_default_3PjXk",
	"primary": "OrderMarker_primary_27Ixe",
	"transparent": "OrderMarker_transparent_164Ms",
	"sm": "OrderMarker_sm_4xOeN",
	"md": "OrderMarker_md_1cqpA",
	"lg": "OrderMarker_lg_3wLK7",
	"xl": "OrderMarker_xl_3eOAU",
	"text": "OrderMarker_text_27Rci"
};
module.exports = exports;
