<template>
  <div :class="$style.section">
    <div :class="[$style.row, $style.left]">
      <div :class="$style.inner">
        <h3 :class="$style.title">#{{ order.id }}</h3>
        <p :class="$style.text">{{ distanceDate }}</p>
      </div>
      <order-marker :color="isPaid ? (order.delivery === 4 ? 'default' : 'primary') : 'default'">
        {{ paymentStatus }}
      </order-marker>
    </div>
    <div :class="$style.separator"></div>
    <div>
      <div :class="[$style.row, $style.left]">
        <div :class="[$style.row, $style.min]">
          <p :class="[$style.text, $style.bold]">{{ $t(`orders.typeOrder.${order.type}`) }}</p>
          <order-marker size="sm" color="transparent">{{
            $t(`orders.type.${order.type}`)
          }}</order-marker>
        </div>
      </div>
      <p v-if="order.track_number" :class="$style.track">
        {{ $t('track') }}: {{ order.track_number }}
      </p>
    </div>
    <div :class="$style.separator"></div>
    <div :class="[$style.row, $style.left]">
      <p :class="[$style.text, $style.gray]">
        <template v-if="order.position_count">
          {{ $tc('item', [order.position_count]) }}
        </template>
      </p>
      <div :class="$style.link" @click="onOrder">
        <span>{{ $t('orders.details') }}</span>
        <icon name="arrow-right" width="6" height="12" />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'

import { formatDateToType } from '@utils/formatDate'
import OrderMarker from '@views/order/list/OrderMarker.vue'

export default Vue.extend({
  components: { OrderMarker },
  name: 'OrdersItem',
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('Locale', ['locale']),
    date() {
      return this.order.updated || this.order.created
    },
    distanceDate() {
      return this.date
        ? this.ucFirst(formatDateToType(this.date, 'MMMM, D. YYYY', this.locale))
        : null
    },
    isPaid() {
      return this.order?.payment_status === 2
    },
    paymentStatus() {
      if (this.isPaid) {
        return this.$t('orders.paid.2')
      }
      if (this.order.delivery) {
        return this.$t(`orders.delivery.${this.order.delivery}`)
      }
      return this.$t('orders.paid.1')
    },
  },
  methods: {
    ...mapMutations('Order', ['setOrder']),
    onOrder() {
      this.setOrder(this.order)
      this.$router.push({ name: 'Orders', params: { id: this.order.id, locale: this.locale } })
    },
    ucFirst(str) {
      return str ? `${str[0].toUpperCase()}${str.slice(1)}` : str
    },
  },
})
</script>

<style lang="scss" module>
.section {
  display: grid;
  padding: 15px;
  background: white;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  row-gap: 9px;
}

.inner {
  display: grid;
  row-gap: 1px;
}

.row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 6px;

  &.left {
    grid-template-columns: 1fr auto;
  }

  &.right {
    grid-template-columns: auto 1fr;
  }

  &.min {
    grid-template-columns: repeat(2, max-content);
  }
}

.title {
  color: black;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.text {
  align-self: center;
  color: black;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  &.bold {
    font-weight: 500;
  }

  &.gray {
    color: $secondary-medium;
  }
}

.track {
  align-self: center;
  justify-self: center;
  width: 100%;
  color: $secondary-medium;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
  text-align: right;
  text-overflow: ellipsis;
}

.separator {
  width: 100%;
  border-top: 1px solid $secondary-light;
}

.link {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  color: black;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-decoration: none;
  column-gap: 15px;
  transition: opacity $transition;

  &:hover {
    opacity: 0.7;
  }

  svg {
    align-self: center;
  }
}
</style>
