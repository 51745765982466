type Query = string
type ParamsValue = any
type Params = {
  [key: string]: ParamsValue
}
export const getQueryParams = (query: Query): Params => {
  const params: Params = {}
  new URLSearchParams(query).forEach((value, key) => {
    let decodedKey = decodeURIComponent(key)
    const decodedValue = decodeURIComponent(value)
    // This key is part of an array
    if (decodedKey.endsWith('[]')) {
      decodedKey = decodedKey.replace('[]', '')
      // eslint-disable-next-line no-unused-expressions
      params[decodedKey] || (params[decodedKey] = [])
      params[decodedKey].push(decodedValue)
      // Just a regular parameter
    } else {
      params[decodedKey] = decodedValue
    }
  })

  return params
}

export default {
  getQueryParams,
}
